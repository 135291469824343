import React from "react";
import styles from "../styles/style.js";
import Headings from "./Headings.js";
import { StaticImage } from "gatsby-plugin-image";

const WhyChooseUs = () => {
  return (
    <section
      id="whyChooseUs"
      className={`bg-[#ffffff] mb-8 ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`${styles.boxWidth}`}>
        <Headings
          titleTop="Why choose us"
          titleBottom="your first call"
          description="See below just a few of the reasons why Intact Electrical should be your first call. Whether your project is in the domestic, commercial or industrial sector, we are here to help."
        />

        <div className="relative px-4 py-16 mt-4">
          <div className="absolute inset-0">
            <div className="absolute inset-y-0 z-0 w-full h-full bg-gray-100 lg:w-3/4" />
          </div>
          <div className="relative">
            <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
              <div className="grid gap-12 row-gap-5 md:grid-cols-2">
                <div className="relative">
                  <div className="relative">
                    <div className="flex items-center justify-center w-10 h-8 mb-3 rounded-full bg-teal-accent-400">
                      <svg
                        className="w-8 h-8 text-teal-900"
                        stroke="currentColor"
                        viewBox="0 0 52 52"
                      >
                        <polygon
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          fill="none"
                          points="29 13 14 29 25 29 23 39 38 23 27 23"
                        />
                      </svg>
                    </div>
                    <h6 className="mb-2 font-semibold leading-5">
                      Knowledge & experience
                    </h6>
                    <p className="text-base text-gray-700">
                      Our project management team consists of electrical experts
                      with decades of combined experience.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-center w-10 h-8 mb-3 rounded-full bg-teal-accent-400">
                    <svg
                      className="w-8 h-8 text-teal-900"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Approved electrical contractors
                  </h6>
                  <p className="text-base text-gray-700">
                    Our workforce are fully trained, highly skilled NICEIC
                    approved contractors and domestic installers.
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-center w-10 h-8 mb-3 rounded-full bg-teal-accent-400">
                    <svg
                      className="w-8 h-8 text-teal-900"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Professional services
                  </h6>
                  <p className="text-base text-gray-700">
                    As perfectionists, we take pride in our workmanship, our
                    team strives to be masters in their field.
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-center w-10 h-8 mb-3 rounded-full bg-teal-accent-400">
                    <svg
                      className="w-8 h-8 text-teal-900"
                      stroke="currentColor"
                      viewBox="0 0 52 52"
                    >
                      <polygon
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"
                      />
                    </svg>
                  </div>
                  <h6 className="mb-2 font-semibold leading-5">
                    Customer guarantee
                  </h6>
                  <p className="text-base text-gray-700">
                    We provide a highly efficient and friendly service first
                    time, every time, taking care of all your needs.
                  </p>
                </div>
              </div>
              <div>
                <StaticImage
                  src="../images/01-tools.jpg"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                  alt="roof with 4 Velux windows"
                  transformOptions={{ fit: "cover", cropFocus: "attention" }}
                  //aspectRatio={1.77}
                  className="w-[100%] h-56 rounded shadow-lg sm:h-96"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
