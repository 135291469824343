import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from '@reach/router';

import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import LeadershipTeam from "../components/LeadershipTeam";
import TalentedPeople from "../components/TalentedPeople";
import WhyChooseUs from "../components/WhyChooseUs";
import FeaturedProject from "../components/FeaturedProject";
import ReactLightboxV2 from "../components/ReactLightboxV2";
import TestimonialSliderDark from "../components/TestimonialSliderDark";
import Seo from "../components/Seo.js";

import {
  aboutFeaturedContent,
  dhlProjectDetails,
  dhlProjectHeader,
  aboutGalleryHeader,
  aboutUsHeroDetails,
  feedback,
} from "../constants";

const AboutUs = () => {
  const location = useLocation();
  //console.log("location: ", location)

  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(
        filter: { name: { in: "hero-brown-books-18th-edition" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
      site {
				siteMetadata {
					siteUrl
				}
			}
      featuredContentImages: allFile(
        filter: {
          name: {
            in: [
              "02-gavin-holding-burnham-fc-shirt"
              "07-black-intact-bike"
              "intact-electrical-vans"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      dhlProjectImages: allFile(
        filter: {
          name: {
            in: [
              "dhl-gallery-01"
              "dhl-gallery-04"
              "dhl-gallery-05"
              "dhl-gallery-16"              
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      galleryImages: allFile(
        filter: {
          name: {
            in: [
              "05-alfie-barnard"
              "06-gavin-working"
              "08-ben-winton-working"
              "12-gavin-working"
              "19-gavin-working"
              "24-richard-working"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("data: ", data);  

  const url = location.href
  //console.log("url: ", url)

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - About us",
        "url":  "https://intact-electrical.co.uk/about-us",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const {
    //id,
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = aboutUsHeroDetails;

  const featuredContentImages = data.featuredContentImages.nodes;

  const dhlProjectImages = data.dhlProjectImages.nodes;
  //console.log("Project images: ", dhlProjectImages)

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  return (
    <>
      <Seo 
        title="About us"
        description="Our expectation became reality, Leadership team, Our talent, Why choose us, your first call, DHL Reading, What people say about us."
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={aboutFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <LeadershipTeam />
      <TalentedPeople />
      <WhyChooseUs />
      <FeaturedProject
        projectDetails={dhlProjectDetails}
        projectImages={dhlProjectImages}
        projectHeader={dhlProjectHeader}
      />
      <ReactLightboxV2
        images={data.galleryImages.nodes}
        galleryContent={aboutGalleryHeader}
        location="about-page"
      />

      {/* <TestimonialSlider testimonials={homeTestimonials} /> */}

      <TestimonialSliderDark feedback={feedback} />
    </>
  );
};

export default AboutUs;
